import { point, MapOptions, TooltipOptions, PathOptions } from 'leaflet';

export const tooltipOptions: TooltipOptions = {
  opacity: 0.9,
  direction: 'right' as const,
  offset: point(18, -25),
  permanent: true
};

export const polygonOptions: PathOptions = {
  weight: 2,
  opacity: 1,
  color: '#FFC125',
  fillOpacity: 0.5
};

export const mapOptions: MapOptions = {
  attributionControl: false,
  minZoom: 7,
  preferCanvas: true,
  tap: false
};
