








import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapOptions, polygonOptions } from './mapOptions';
import { tileLayerColor, carIcon, parkingIcon, selectedCarIcon } from './mapIcons';
import Loading from '@/components/ui/Loading.vue';
import 'leaflet';
import 'leaflet.markercluster';
import {
  Map as LeafletMap,
  map,
  marker,
  GeoJSON,
  geoJSON,
  LayerGroup,
  Marker,
  layerGroup,
  MarkerCluster,
  MarkerClusterGroup,
  markerClusterGroup,
  divIcon,
  LatLngExpression,
  latLngBounds,
  control,
  Control
} from 'leaflet';
import { userLocation } from './userLocation';
import { LatLngForMap, GeoJsonForMap, WithId, MapElementId, MapMetadata } from './locationParser';

type CarsInput = (LatLngExpression | LatLngForMap)[];
type ParkingsInput = (LatLngExpression | LatLngForMap)[];
type ParkingAreasInput = (GeoJSON | GeoJsonForMap)[];

@Component({
  components: {
    Loading
  }
})
export default class MapBox extends Vue {
  @Prop({ type: Number, default: 10 }) readonly zoom!: number;
  @Prop({ type: Number, default: 13 }) readonly parkingSwapThreshold!: number;
  @Prop({ type: Number, default: 14.444139 }) readonly defaultLongitude!: number;
  @Prop({ type: Number, default: 50.103467 }) readonly defaultLatitude!: number;

  @Prop({ type: Boolean, default: false }) readonly displayUserLocation!: boolean;
  @Prop({ type: Boolean, default: true }) readonly carsClustering!: boolean;
  @Prop({ type: Boolean, default: true }) readonly parkingsClustering!: boolean;
  @Prop({ type: Boolean, default: true }) readonly swapParkingIconsWithAreas!: boolean;
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
  @Prop({ type: Boolean, default: false }) readonly showZoomControl!: boolean;

  @Prop({ type: Array, default: () => [] }) readonly cars!: CarsInput;
  @Prop({ type: Array, default: () => [] }) readonly parkings!: ParkingsInput;
  @Prop({ type: Array, default: () => [] }) readonly parkingAreas!: ParkingAreasInput;
  @Prop({ type: Array, default: () => [] }) readonly highlightedCars!: MapElementId[];
  @Prop({ type: Array }) readonly focusTo?: LatLngExpression | null;

  private userLocator?: L.Control.Locate;
  private firstLocationFound = true;

  private map!: LeafletMap;

  private zoomControl!: Control.Zoom;
  private parkingAreasLayer!: GeoJSON;
  private parkingIconsLayer!: LayerGroup;
  private parkingsClusterLayer!: MarkerClusterGroup;
  private carsIconslayer!: LayerGroup;
  private carsClusterLayer!: MarkerClusterGroup;

  private carMarkers: WithId<Marker>[] = [];
  private parkingMarkers: WithId<Marker>[] = [];
  private parkingGeoJSONs: WithId<GeoJSON>[] = [];

  private parkingAreaZIndex = 0;
  private parkingIconZIndex = 1;
  private carIconZIndex = 2;
  private selectedCarIconZIndex = 3;

  private get carsWatch() {
    const locations = [];

    for (let car of this.cars) {
      if (Object.keys(car).includes('element')) {
        car = car as LatLngForMap;
        locations.push(`${car.element.toString()}${car.id}`);
      } else {
        locations.push(`${car.toString()}${null}`);
      }
    }
    return locations.toString();
  }

  private get parkingsWatch() {
    const locations = [];

    for (let car of this.parkings) {
      if (Object.keys(car).includes('element')) {
        car = car as LatLngForMap;
        locations.push(`${car.element.toString()}${car.id}`);
      } else {
        locations.push(`${car.toString()}${null}`);
      }
    }
    return locations.toString();
  }

  @Watch('carsWatch')
  setCarsWatch() {
    this.setCars(this.cars);
    this.centerMarkers();
  }

  @Watch('parkingsWatch')
  setParkingsWatch() {
    this.setParkings(this.parkings);
    this.centerMarkers();
  }

  @Watch('parkingAreas', { deep: true })
  setParkingAreasWatch(areas: ParkingAreasInput) {
    this.setParkingAreas(areas);
  }

  @Watch('focusTo', { deep: true })
  focusToWatch(to?: LatLngExpression) {
    if (!to) return;

    this.map.flyTo(to, 14, {
      animate: true,
      duration: 0.4
    });
  }

  @Watch('highlightedCars', { deep: true })
  highlightCarsWatch() {
    this.highligtCars();
  }

  @Watch('displayUserLocation')
  userLocationWatch(value: boolean) {
    if (value) {
      this.startUserLocation();
    }
  }

  @Watch('zoom')
  zoomWatch(zoom: number) {
    if (this.map) {
      this.map.setZoom(zoom);
    }
  }

  @Watch('showZoomControl')
  zoomControlWatch(zoomControl: boolean) {
    this.setZoomControl(zoomControl);
  }

  mounted() {
    this.parkingAreasLayer = geoJSON();
    this.parkingIconsLayer = layerGroup();
    this.carsIconslayer = layerGroup();
    this.zoomControl = control.zoom();

    this.carsClusterLayer = markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      iconCreateFunction: (c) => {
        return divIcon({
          html: `<div class="leaflet-cluster"><div class="leaflet-cluster-inner">${c.getChildCount()}</div></div>`
        });
      }
    });
    this.parkingsClusterLayer = markerClusterGroup({
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      iconCreateFunction: (c) => {
        return divIcon({
          html: `<div class="leaflet-cluster"><div class="leaflet-cluster-parking">${c.getChildCount()}</div></div>`
        });
      }
    });

    this.map = map(this.$refs['map'] as HTMLElement, { zoomControl: this.showZoomControl, ...mapOptions }).setView(
      this.focusTo || [this.defaultLatitude, this.defaultLongitude],
      this.zoom
    );

    tileLayerColor().addTo(this.map);
    this.map.on('zoom', this.setParkingLayersOnMap);

    if (this.displayUserLocation) {
      this.startUserLocation();
    }

    this.setCars(this.cars);
    this.setParkings(this.parkings);
    this.setParkingAreas(this.parkingAreas);
    this.centerMarkers();
  }

  unmounted() {
    if (this.userLocator) {
      this.userLocator.stop();
    }
  }

  private setCars(positions: CarsInput) {
    if (!positions || !positions.length) {
      return;
    }

    this.clearCars();

    for (const pos of positions) {
      let carMarker: WithId<Marker>;

      if (Object.keys(pos).includes('element')) {
        const posWithId = pos as LatLngForMap;
        carMarker = {
          element: marker(posWithId.element, { icon: carIcon(), interactive: true })
            .on('click', () => this.carClicked(posWithId.id))
            .setZIndexOffset(this.carIconZIndex)
            .addTo(this.carsClustering ? this.carsClusterLayer : this.carsIconslayer),
          id: posWithId.id
        };
      } else {
        carMarker = {
          element: marker(pos as LatLngExpression, { icon: carIcon(), interactive: false })
            .setZIndexOffset(this.carIconZIndex)
            .addTo(this.carsClustering ? this.carsClusterLayer : this.carsIconslayer),
          id: null
        };
      }

      if (Object.keys(pos).includes('metadata') && typeof (pos as LatLngForMap).metadata !== 'undefined') {
        const metadata = (pos as LatLngForMap).metadata as MapMetadata;
        metadata.tooltip &&
          carMarker.element
            .bindTooltip(metadata.tooltip, {
              permanent: metadata.permanentTooltip || false
            })
            .openTooltip();
      }

      this.carMarkers.push(carMarker);
    }

    if (this.carsClustering) {
      this.carsClusterLayer.addTo(this.map);
    } else {
      this.carsIconslayer.addTo(this.map);
    }

    this.highligtCars();
  }

  private setParkings(positions: ParkingsInput) {
    if (!positions || !positions.length) {
      return;
    }

    this.clearParkings();

    for (const pos of positions) {
      if (Object.keys(pos).includes('element')) {
        const posWithId = pos as LatLngForMap;
        this.parkingMarkers.push({
          element: marker(posWithId.element, { icon: parkingIcon(), interactive: true })
            .on('click', () => this.parkingClicked(posWithId.id))
            .setZIndexOffset(this.parkingIconZIndex)
            .addTo(this.parkingsClustering ? this.parkingsClusterLayer : this.parkingIconsLayer),
          id: posWithId.id
        });
      } else {
        this.parkingMarkers.push({
          element: marker(pos as LatLngExpression, { icon: parkingIcon() })
            .setZIndexOffset(this.parkingIconZIndex)
            .addTo(this.parkingsClustering ? this.parkingsClusterLayer : this.parkingIconsLayer),
          id: null
        });
      }
    }

    if (this.parkingsClustering) {
      this.parkingsClusterLayer.addTo(this.map);
    } else {
      this.parkingIconsLayer.addTo(this.map);
    }

    this.setParkingLayersOnMap();
  }

  private setParkingAreas(areas: ParkingAreasInput) {
    if (!areas.length) {
      return;
    }

    this.clearParkingAreas();

    for (const area of areas) {
      if (Object.keys(area).includes('element')) {
        const areaWithId = area as GeoJsonForMap;
        this.parkingGeoJSONs.push({
          element: geoJSON(areaWithId.element)
            .setStyle(polygonOptions)
            .setZIndex(this.parkingAreaZIndex)
            .on('click', () => this.parkingAreaClicked(areaWithId.id))
            .addTo(this.parkingAreasLayer),
          id: areaWithId.id
        });
      } else {
        this.parkingGeoJSONs.push({
          element: geoJSON(area as unknown as GeoJSON.FeatureCollection)
            .setStyle(polygonOptions)
            .setZIndex(this.parkingAreaZIndex)
            .addTo(this.parkingAreasLayer),
          id: null
        });
      }
    }

    this.setParkingLayersOnMap();
  }

  private highligtCars() {
    for (const { element } of this.carMarkers) {
      element.setIcon(carIcon());
      element.setZIndexOffset(this.carIconZIndex);

      if (!this.carsClustering) continue;

      const cluster = this.carsClusterLayer.getVisibleParent(element) as MarkerCluster | undefined;

      if (cluster && typeof cluster.getChildCount === 'function') {
        cluster.setIcon(
          divIcon({
            html: `<div class="leaflet-cluster"><div class="leaflet-cluster-inner">${cluster.getChildCount()}</div></div>`
          })
        );
      }
    }

    const markersToHighlight = this.carMarkers.filter((m) => m.id && this.highlightedCars.includes(m.id));

    for (const { element } of markersToHighlight) {
      element.setZIndexOffset(this.selectedCarIconZIndex);
      element.setIcon(selectedCarIcon());

      if (!this.carsClustering) continue;

      const cluster = this.carsClusterLayer.getVisibleParent(element) as MarkerCluster | undefined;

      if (cluster && typeof cluster.getChildCount === 'function') {
        cluster.setIcon(
          divIcon({
            html: `<div class="leaflet-cluster selected"><div class="leaflet-cluster-inner selected">${cluster.getChildCount()}</div></div>`
          })
        );
      }
    }
  }

  private setParkingLayersOnMap() {
    if (!this.swapParkingIconsWithAreas) {
      this.parkingIconsLayer.addTo(this.map);
      this.parkingAreasLayer.addTo(this.map);
      return;
    }

    if (this.map.getZoom() > this.parkingSwapThreshold) {
      this.map.removeLayer(this.parkingsClustering ? this.parkingsClusterLayer : this.parkingIconsLayer);
      this.parkingAreasLayer.addTo(this.map);
    } else {
      this.map.addLayer(this.parkingsClustering ? this.parkingsClusterLayer : this.parkingIconsLayer);
      this.parkingAreasLayer.removeFrom(this.map);
    }
  }

  private centerMarkers(extraPosition?: LatLngExpression) {
    const markersToFocus = [...this.carMarkers.map((e) => e.element), ...this.parkingMarkers.map((e) => e.element)];

    if (markersToFocus.length < 1) return;

    const locations: LatLngExpression[] = markersToFocus.map((marker) => marker.getLatLng());
    extraPosition && locations.push(extraPosition);

    this.map.fitBounds(latLngBounds(locations), {
      padding: [45, 45],
      animate: true,
      duration: 0.4
    });
  }

  private setZoomControl(enableZoomControl: boolean) {
    if (!enableZoomControl) {
      this.map.removeControl(this.zoomControl);
    } else {
      this.map.addControl(this.zoomControl);
    }
  }

  private clearCars() {
    if (this.carsClustering) {
      this.carsClusterLayer.clearLayers();
      this.carsClusterLayer.removeFrom(this.map);
    } else {
      this.carsIconslayer.clearLayers();
      this.carsIconslayer.removeFrom(this.map);
    }

    this.carMarkers = [];
  }

  private clearParkings() {
    if (this.parkingsClustering) {
      this.parkingsClusterLayer.clearLayers();
      this.parkingsClusterLayer.removeFrom(this.map);
    } else {
      this.parkingIconsLayer.clearLayers();
      this.parkingIconsLayer.removeFrom(this.map);
    }

    this.parkingMarkers = [];
  }

  private startUserLocation() {
    if (this.userLocator) {
      return;
    }

    this.map.on('locationfound', (location) => {
      if (this.firstLocationFound) {
        this.centerMarkers([location.latlng.lat, location.latlng.lng]);
        this.firstLocationFound = false;
      }
    });
    this.userLocator = userLocation(this.map);
    this.userLocator.start();
  }

  private clearParkingAreas() {
    this.parkingAreasLayer.clearLayers();
    this.parkingAreasLayer.removeFrom(this.map);

    this.parkingGeoJSONs = [];
  }

  private carClicked(id: MapElementId) {
    this.$emit('carClicked', id);
  }

  private parkingClicked(id: MapElementId) {
    this.$emit('parkingClicked', id);
  }

  private parkingAreaClicked(id: MapElementId) {
    this.$emit('parkingAreaClicked', id);
  }
}
