import * as L from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';

type locateOptions = L.LocateOptions & L.Control.LocateOptions;

export const userLocation = (map: L.Map, options?: Partial<locateOptions>) => {
  const locateOptions: locateOptions = {
    cacheLocation: true,
    flyTo: true,
    position: 'topleft',
    showCompass: true,
    watch: true,
    setView: true,
    enableHighAccuracy: true
  };

  return L.control.locate({ ...locateOptions, ...options }).addTo(map);
};
