import { icon, tileLayer } from 'leaflet';

export const carIcon = () => {
  return icon({
    iconUrl: 'https://d1qyg3ezd7xlmk.cloudfront.net/map-icons/truck-icon-selected.svg',
    iconSize: [40, 40]
  });
};

export const selectedCarIcon = () => {
  return icon({
    iconUrl: 'https://d1qyg3ezd7xlmk.cloudfront.net/map-icons/truck-icon-dark.svg',
    iconSize: [44, 44]
  });
};

export const parkingIcon = () => {
  return icon({
    iconUrl: 'https://d1qyg3ezd7xlmk.cloudfront.net/map-icons/parking-sign-128.png',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    popupAnchor: [-3, -76]
  });
};

export const tileLayerColor = () => {
  return tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    maxZoom: 18,
    id: 'mapbox/light-v10',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: 'pk.eyJ1IjoidHR0dG9tYXMiLCJhIjoiY2p5ZDduODU4MHA5OTNibnZwejVwcmtqciJ9.VfOTuHVpms9_0pNkwdFjgg'
  });
};
